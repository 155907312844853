import { IShape } from '~/components/PaperWithShapes/types';

export default (left?: string, right?: string): IShape[] => [
  {
    id: 1,
    position: {
      left: 0,
      top: 0,
    },
    svg: left || '',
  },
  {
    id: 2,
    position: {
      right: 0,
      top: 89,
    },
    svg: right || '',
  },
];
