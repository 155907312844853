/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useThemeProps } from '@mui/system';
import React, { ReactElement } from 'react';

import { StyledImage, StyledPaper } from './styles';
import { IPaperWithShapes } from './types';

const PaperWithShapes = (inProps: React.PropsWithChildren<IPaperWithShapes>): ReactElement => {
  const props = useThemeProps({ name: 'PaperWithShapes', props: inProps });
  const { shapes, children, backgroundColor, PaperProps = {} } = props;

  return (
    <StyledPaper {...PaperProps} $backgroundColor={backgroundColor}>
      {shapes.map(shape => (
        <StyledImage
          $position={shape.position}
          alt=""
          data-chromatic="ignore"
          key={shape.id}
          src={shape.svg}
          style={{ userSelect: 'none' }}
          sx={shape.sx || {}}
        />
      ))}
      {children}
    </StyledPaper>
  );
};

export default PaperWithShapes;
