import { boolean } from 'boolean';
import { usePathname } from 'next/navigation';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import ReactWalkme from 'react-walkme';

import FeedbackDialog from '~/components/FeedbackDialog';
import ENV from '~/constants/env';
import USER_TYPES from '~/constants/userTypes';
import fixWalkMeMenuAnimations from '~/helpers/walkme/fixWalkMeMenuAnimations';
import isMenuOpen from '~/helpers/walkme/isMenuOpen';
import onEscPressedCloseMenu from '~/helpers/walkme/onEscPressedCloseMenu';
import replaceWalkMeHTMLElements from '~/helpers/walkme/replaceWalkMeHTMLElements';
import { WalkMeSetUserData } from '~/helpers/walkme/WalkMeInsights';
import useActiveUser from '~/hooks/useActiveUser';
import useLogger from '~/hooks/useLogger';
import { ILogger } from '~/types';

export interface IWalkMeContextData {
  isMenuAvailable: boolean;
  openMenu: () => void;
  closeMenu: () => void;
}

export const WalkMeContext = createContext<IWalkMeContextData>({
  isMenuAvailable: false,
} as IWalkMeContextData);

type VoidFunction = () => void;

const WalkMeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const logger: ILogger = useLogger();
  const maintenanceMode = boolean(ENV.MAINTENANCE_MODE);
  const [isMenuAvailable, setIsMenuAvailable] = useState(false);
  const [walkMeStarted, setWalkMeStarted] = useState(false);

  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const pathname = usePathname();
  const activeUser = useActiveUser();
  const isUserLoggedIn = Boolean(activeUser);

  const closeMenu = useCallback(() => {
    const isOpen = isMenuOpen();
    if (!isMenuAvailable || !isOpen) return;

    window?.WalkMePlayerAPI?.toggleMenu();
    fixWalkMeMenuAnimations(isOpen);
  }, [isMenuAvailable]);

  const openMenu = useCallback(() => {
    const isOpen = isMenuOpen();
    if (!isMenuAvailable || isOpen) return;

    window?.WalkMePlayerAPI?.toggleMenu();
    fixWalkMeMenuAnimations(isOpen);

    const openFeedbackDialog = (): void => {
      setFeedbackDialogOpen(true);
      closeMenu();
    };

    replaceWalkMeHTMLElements({ closeMenu, openFeedbackDialog });
  }, [closeMenu, isMenuAvailable]);

  useEffect((): VoidFunction => {
    if (!activeUser?.uid) return () => ({});

    window.walkme_ready = (): void => {
      setIsMenuAvailable(true);
    };

    // This is required for the WalkMe Menu to start correctly
    WalkMeSetUserData({
      role: activeUser?.type === USER_TYPES.LDAP ? 'ldap_user' : undefined,
      uid: activeUser?.uid,
    });

    return onEscPressedCloseMenu(closeMenu);
  }, [activeUser]);

  useEffect(() => {
    if (!pathname?.includes('login')) {
      ReactWalkme.getWalkMeAPI();
    }
  }, [pathname]);

  useEffect((): void => {
    const initWalkme = (): void => {
      const walkmeInstance = ReactWalkme.walkme();

      if (walkmeInstance.error) {
        const walkmeId = ENV.WALKME_ID as string;
        const walkmeTest = boolean(ENV.WALKME_TEST);
        const walkmeDebug = boolean(ENV.WALKME_DEBUG);

        ReactWalkme.initialize(walkmeId, walkmeTest, walkmeDebug);
      }
    };

    if (!maintenanceMode && !walkMeStarted && isUserLoggedIn) {
      setWalkMeStarted(true);
      initWalkme();
    }
  }, [logger, maintenanceMode, walkMeStarted, isUserLoggedIn]);

  const providerValue = useMemo(
    () =>
      ({
        closeMenu,
        isMenuAvailable,
        openMenu,
      }) satisfies IWalkMeContextData,
    [isMenuAvailable, openMenu, closeMenu],
  );

  return (
    <>
      <WalkMeContext.Provider value={providerValue}>{children}</WalkMeContext.Provider>
      <FeedbackDialog onClose={() => setFeedbackDialogOpen(false)} open={feedbackDialogOpen} />
    </>
  );
};

export default WalkMeProvider;
