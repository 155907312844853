// eslint-disable-next-line import/no-extraneous-dependencies
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ReactElement, useCallback } from 'react';

import getFeedbackShapes from '~/components/Feedback/getFeedbackShapes';
import { CloseButton, CloseIcon, FeedbackInput, StyledStack, StyledSubmitButton } from '~/components/Feedback/styles';
import PaperWithShapes from '~/components/PaperWithShapes';
import tagIds from '~/constants/tagIds';
import isValidEmail from '~/helpers/isValidEmail';

const TITLE = 'We want your Feedback!';
const TEXT_LIMIT = 10000;
const INPUT_PLACEHOLDER = `Do you have a specific question? How can we help?

How can we make this tool more useful to you?

How does this tool help you achieve your goals?`;

export type SubmitFeedbackType = (input: { email: string; text: string }) => Promise<{
  success: boolean;
  errors?: unknown[];
}>;

interface IProps {
  email: string | null;
  loading: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  onSubmit: SubmitFeedbackType;
  onError?: (error: unknown) => void;
  paddingBottom?: string;
  paddingTop?: string;
  buttonCloseStyle?: React.CSSProperties;
  bgImgLeft?: string;
  bgImgRight?: string;
  title?: string;
  placeholder?: string;
  footer?: () => ReactElement;
}

export const Feedback: React.FC<IProps> = ({
  email: emailProps,
  loading,
  onCancel,
  onSuccess,
  onSubmit,
  onError,
  paddingBottom,
  paddingTop,
  buttonCloseStyle,
  bgImgLeft,
  bgImgRight,
  title,
  placeholder,
  footer,
}: IProps) => {
  const [textValue, setTextValue] = React.useState('');
  const [email, setEmail] = React.useState(emailProps);

  React.useEffect((): void => {
    setEmail(emailProps);
  }, [emailProps]);

  const submit = useCallback(async () => {
    try {
      const response = await onSubmit({ email: email!, text: textValue });

      if (response.success && onSuccess) onSuccess();

      if (response.errors && onError) onError(response.errors);
    } catch (e) {
      if (onError) onError(e);
    }
  }, [email, onError, onSubmit, onSuccess, textValue]);

  const renderButton: () => React.ReactElement = () => (
    <StyledSubmitButton
      color="primary"
      disabled={loading || textValue.length === 0 || !email || !isValidEmail(email)}
      fullWidth
      id={tagIds.COMPONENTS.FEEDBACK.BUTTON_SUBMIT}
      loading={loading}
      onClick={() => {
        // eslint-disable-next-line no-void
        void submit();
      }}
      size="large"
      variant="contained"
    >
      Submit
    </StyledSubmitButton>
  );

  return (
    <PaperWithShapes
      PaperProps={{
        sx: {
          height: '100%',
          overflowY: 'auto!important',
          width: '100%',
        },
      }}
      shapes={getFeedbackShapes(bgImgLeft, bgImgRight)}
    >
      <StyledStack gap="24px" paddingBottom={paddingBottom} paddingTop={paddingTop}>
        <Typography align="center" sx={{ p: 0 }} variant="h4">
          {title || TITLE}
        </Typography>
        {!!onCancel && (
          <CloseButton disabled={loading} id={tagIds.COMPONENTS.FEEDBACK.BUTTON_CLOSE} onClick={onCancel} size="large" style={buttonCloseStyle}>
            <CloseIcon />
          </CloseButton>
        )}
        {!emailProps && (
          <Stack gap={0.5}>
            <TextField
              data-testid={tagIds.COMPONENTS.FEEDBACK.EMAIL}
              error={!!email && !isValidEmail(email)}
              fullWidth
              helperText={email && !isValidEmail(email) ? 'Invalid email address' : undefined}
              id={tagIds.COMPONENTS.FEEDBACK.EMAIL}
              inputProps={{
                sx: { p: 2 },
              }}
              onChange={(e): void => {
                setEmail(e.target.value);
              }}
              placeholder="Email Address"
              sx={{ backgroundColor: 'white' }}
              type="email"
              value={email}
            />
          </Stack>
        )}
        <Stack gap={0.5}>
          <FeedbackInput
            data-testid={tagIds.COMPONENTS.FEEDBACK.TEXTAREA}
            fullWidth
            id={tagIds.COMPONENTS.FEEDBACK.TEXTAREA}
            inputProps={{
              maxLength: TEXT_LIMIT,
              sx: { p: 2 },
            }}
            multiline
            onChange={(e): void => {
              setTextValue(e.target.value);
            }}
            placeholder={placeholder || INPUT_PLACEHOLDER}
            rows={8}
            value={textValue}
          />

          <Typography align="right" variant="body2">
            {textValue.length}/{TEXT_LIMIT}
          </Typography>
        </Stack>

        {footer && footer()}
        {renderButton()}
      </StyledStack>
    </PaperWithShapes>
  );
};

export default Feedback;
