import Paper, { PaperProps, paperClasses } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export interface IPosition {
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
}

interface IImageProps {
  $position: IPosition;
}

interface IPaperProps extends PaperProps {
  $backgroundColor?: string;
}

export const StyledImage = styled('img')<IImageProps>(({ $position }) => ({
  bottom: $position.bottom,
  left: $position.left,
  position: 'absolute',
  right: $position.right,
  top: $position.top,
  zIndex: -2,
}));

export const StyledPaper = styled(Paper, {
  name: 'PaperWithShapes',
  overridesResolver: (_props, styles) => styles.paper,
  slot: 'Paper',
})<IPaperProps>(({ $backgroundColor, theme }) => ({
  [`&.${paperClasses.root}`]: {
    backgroundColor: $backgroundColor || theme.palette.common.white,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    overflowY: 'hidden',
    position: 'relative',
    zIndex: 0,
  },
}));
