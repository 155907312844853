const ACTIVE = 'active';

export default (isMenuOpen: boolean): void => {
  if (!isMenuOpen) {
    // Delay to keep elements hidden while walkme default animation is running.
    setTimeout(() => {
      const menu = document.getElementById('walkme-menu');
      menu?.classList.add(ACTIVE);

      const overlay = document.getElementById('walkme-overlay');
      overlay?.classList.add(ACTIVE);
    }, 400);

    return;
  }

  const menu = document.getElementById('walkme-menu');
  menu?.classList.remove(ACTIVE);

  const overlay = document.getElementById('walkme-overlay');
  overlay?.classList.remove(ACTIVE);
};
