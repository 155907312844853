import Close from '@actinc/dls/icons/Close';
import { THEME_ENCOURAGE_E4E } from '@actinc/dls/styles/themeEncourageE4E';
// eslint-disable-next-line import/no-extraneous-dependencies
import LoadingButton from '@mui/lab/LoadingButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import IconButton from '~/components/IconButton';

export const StyledSubmitButton = styled(LoadingButton)(() => ({
  alignSelf: 'center',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: (theme as typeof THEME_ENCOURAGE_E4E).palette.branding.sapphire[50],
  padding: '8px',
  position: 'absolute',
  right: theme.spacing(2),
  top: `20px`,
}));

export const FeedbackInput = styled(OutlinedInput)(() => ({
  backgroundColor: 'white',
  minHeight: '240px',
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
  color: (theme as typeof THEME_ENCOURAGE_E4E).palette.branding.sapphire[500],
  height: '24px',
  width: '24px',
}));

interface IStackProps {
  paddingBottom?: string;
  paddingTop?: string;
}

export const StyledStack = styled(Stack)(({ paddingBottom, paddingTop }: IStackProps) => ({
  paddingBottom: paddingBottom || '24px',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: paddingTop || '60px',
}));
