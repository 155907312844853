import { useContext } from 'react';

import { IWalkMeContextData, WalkMeContext } from '~/providers/WalkMeProvider';

export const useWalkMe = (): IWalkMeContextData => {
  const context = useContext(WalkMeContext);

  if (!context) {
    throw new Error('useWalkMe must be used within a WalkMeContextProvider');
  }

  return context;
};

export default useWalkMe;
