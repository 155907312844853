export default ({
  closeMenu,
  openFeedbackDialog,
}: {
  closeMenu: () => void;
  openFeedbackDialog: () => void;
}): NodeJS.Timeout =>
  // Delay to wait for the menu to be shown before getting HTML Elements
  setTimeout(() => {
    // override WalkMe behavior when closing the menu
    const buttonClose = document.querySelector('.walkme-minimize') as HTMLElement;
    buttonClose?.addEventListener('click', closeMenu);
    const overlay = document.querySelector('.walkme-overlay') as HTMLElement;
    overlay?.addEventListener('click', closeMenu);

    // create new HTML Element for the "Share your feedback" item and override the WalkMe default behavior
    const element = document.querySelector('.walkme-shuttle') as HTMLElement;
    const newElement = document.createElement('div');
    newElement.className = element.className;
    newElement.innerHTML = element.innerHTML;
    newElement?.addEventListener('click', openFeedbackDialog);

    element.replaceWith(newElement);
  }, 200);
