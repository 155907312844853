import THEME_ENCOURAGE_E4E from '@actinc/dls/styles/themeEncourageE4E';

export default {
  ...THEME_ENCOURAGE_E4E,
  components: {
    ...THEME_ENCOURAGE_E4E.components,
    MuiCssBaseline: {
      styleOverrides: {
        // Adding theme colors to walkme css
        ':root': {
          '--navy-500': THEME_ENCOURAGE_E4E.palette.branding.navy[500],
          '--sapphire-50': THEME_ENCOURAGE_E4E.palette.branding.sapphire[50],
          '--sapphire-500': THEME_ENCOURAGE_E4E.palette.branding.sapphire[500],
          '--surface-100': THEME_ENCOURAGE_E4E.palette.branding.surface[100],
          '--text-over-light-high': THEME_ENCOURAGE_E4E.palette.text.overLight.high,
          '--text-over-light-medium': THEME_ENCOURAGE_E4E.palette.text.overLight.medium,
        },
      },
    },
  },
};
