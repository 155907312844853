import Box, { BoxProps } from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { styled } from '~/helpers/styled';

export const MenuRootGrid = styled(Grid)<GridProps & { $open: boolean }>(({ $open, theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  borderRadius: '8px',
  cursor: 'pointer',
  gap: `${theme.spacing(1)}`,
  height: '44px',
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  userSelect: 'none',
  ...($open && {
    backgroundColor: theme.palette.branding.sapphire[50],
  }),
  width: 'auto',
}));

export const OverlayBox = styled(Box)<BoxProps & { $open: boolean }>(({ $open, theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  bottom: 0,
  left: 0,
  opacity: $open ? 1 : 0,
  pointerEvents: 'none',
  position: 'fixed',
  right: 0,
  top: theme.customDims.heights.header,
  transition: 'opacity 0.5s ease, z-index 0.5s ease',
  zIndex: 1001,
}));

export const UserFullNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.branding.sapphire[700],
  lineHeight: '20px',
  whiteSpace: 'nowrap',
}));
