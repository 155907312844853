type VoidFunction = () => void;

const onEscPressedCloseMenu = (closeMenu: VoidFunction): VoidFunction => {
  const handleKeyDown = (event: KeyboardEvent): void => {
    event.stopPropagation();
    if (event.key === 'Escape') {
      closeMenu();
    }
  };

  document.addEventListener('keydown', handleKeyDown, true);

  // Cleanup function to remove the listener
  return (): void => {
    document.removeEventListener('keydown', handleKeyDown);
  };
};

export default onEscPressedCloseMenu;
