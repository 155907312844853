import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import { useCreateHelpDeskTicketMutation } from '~/__generated__/graphql';
import { Feedback } from '~/components/Feedback';
import useActiveUser from '~/hooks/useActiveUser';
import useAlert from '~/hooks/useAlert';
import useLogger from '~/hooks/useLogger';
import { ILogger } from '~/types';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const FeedbackDialog: React.FC<IProps> = ({ onClose, open }: IProps) => {
  const activeUser = useActiveUser();
  const userEmail = useMemo(() => activeUser?.email || null, [activeUser]);

  const [createHelpDeskTicket] = useCreateHelpDeskTicketMutation();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const logger: ILogger = useLogger();

  const trySubmitHelpdeskTicket = useCallback(
    async ({ email, text }: { email: string; text: string }): Promise<{ success: boolean; errors?: unknown[] }> => {
      setLoading(true);
      const input = {
        description: text,
        email,
        subject: `Educator Feedback`,
        userName: email,
      };
      const response = await createHelpDeskTicket({
        context: { apiName: 'federatedGraph' },
        variables: {
          input,
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const success = (response?.data?.encourageEducatorCreateHelpDeskTicket?.__typename || '') === 'EncourageEducatorCreateHelpDeskTicketSuccess';

      return {
        errors: response.errors as unknown[],
        success,
      };
    },
    [createHelpDeskTicket],
  );

  const onError = useCallback(
    (e: unknown) => {
      logger.error(e);
      setLoading(false);
      alert.showErrorAlert('Error submitting feedback, try again later!');
    },
    [alert, logger],
  );

  const onSuccess = useCallback(() => {
    setLoading(false);
    alert.showSuccessAlert('Submitted successfully. Thanks for your feedback!');
    onClose();
  }, [onClose, alert]);

  const feedbackFooter: () => ReactElement = useCallback(
    () => <Typography>We&apos;ll reply to the email connected to your myOptions Encourage account (M-F, 8-5 p.m. EST).</Typography>,
    [],
  );

  return (
    <Dialog onClose={onClose} open={open}>
      <Feedback
        bgImgLeft="/static/img/feedback_l.svg"
        bgImgRight="/static/img/half_circle_yellow.svg"
        email={userEmail}
        footer={feedbackFooter}
        loading={loading}
        onCancel={onClose}
        onError={onError}
        onSubmit={trySubmitHelpdeskTicket}
        onSuccess={onSuccess}
        title="Ask Questions! Share Feedback."
      />
    </Dialog>
  );
};

export default FeedbackDialog;
